import styled from '@emotion/styled';
import { Flex } from '@pairy/pds-react';

import { Z_INDEX } from '@/constants/zIndex';

export const Container = styled(Flex)`
  position: fixed;
  z-index: ${Z_INDEX.layoutHeader};
`;

export const Wrapper = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc((100% - 1080px) / 2);
  border-bottom: 1px solid ${({ theme }) => theme.colors.zinc_200};

  background-color: ${({ theme }) => theme.colors.white};
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
