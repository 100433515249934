import Link from 'next/link';
import { useRouter } from 'next/router';
import { Text } from '@pairy/pds-react';

import * as Styled from './index.styles';

const NAVIGATION_NAMES = {
  Pdf: {
    name: 'PDF',
    route: '/user/pdf',
  },
  Link: {
    name: '링크',
    route: '/user/link',
  },
  Client: {
    name: '고객',
    route: '/user/client',
  },
} as const;

const Navigation = () => {
  const router = useRouter();

  return (
    <nav>
      <Styled.Ul>
        {Object.values(NAVIGATION_NAMES).map(({ name, route }) => (
          <li key={name}>
            <Link href={route}>
              <Styled.A
                data-state={
                  router.pathname.includes(route) ? 'active' : 'inactive'
                }
              >
                <Text variant="lg-semibold" color="inherit">
                  {name}
                </Text>
              </Styled.A>
            </Link>
          </li>
        ))}
      </Styled.Ul>
    </nav>
  );
};

export default Navigation;
