import { useRouter } from 'next/router';
import { useAtomValue } from 'jotai';
import { Button, Icon, Stack } from '@pairy/pds-react';

import { userInfoAtom } from '@/state/user/atom';

import TopBanner from '../../TopBanner';

import Logo from './Logo';
import * as Styled from './index.styles';
import Navigation from './Navigation';

const Header = () => {
  const router = useRouter();
  const userInfo = useAtomValue(userInfoAtom);

  return (
    <Styled.Container direction="column">
      <TopBanner />
      <Styled.Wrapper>
        <Styled.Left>
          <Logo />
          <Navigation />
        </Styled.Left>
        <Stack gap={8} width="fit-content">
          <Button
            variant="viridian_300"
            paddingY={8}
            paddingX={16}
            leftIcon={<Icon icon="ph:sparkle-fill" size={19} />}
            onClick={() => router.push('/change-log')}
          >
            <span>기능 업데이트</span>
          </Button>
          <Button
            variant="zinc_200"
            leftIcon={<Icon icon="ph:house-fill" size={19} />}
            onClick={() => {
              window.open('https://salesclue.io', '_blank');
            }}
          >
            홈으로
          </Button>
          <div>
            <Button
              variant="zinc_200"
              leftIcon={<Icon icon="ph:user-circle" size={19} />}
              paddingY={9}
              paddingX={16}
              color="zinc_400"
              onClick={() => router.push('/user/setting')}
            >
              <span>{userInfo?.name}</span>
            </Button>
          </div>
        </Stack>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default Header;
