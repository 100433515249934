import styled from '@emotion/styled';

export const Ul = styled.ul`
  display: flex;
  gap: 8px;
`;

export const A = styled.a`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  color: ${({ theme }) => theme.colors.zinc_400};
  cursor: pointer;
  gap: 8px;

  transition: all 0.15s ease-in-out;

  &[data-state='active'] {
    border-bottom: 2px solid ${({ theme }) => theme.colors.skyblue_400};

    background: ${({ theme }) => theme.colors.skyblue_50};
    color: ${({ theme }) => theme.colors.skyblue_400};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.zinc_200};
  }
`;
