import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo } from 'react';

import * as Styled from './index.styles';

const Logo = () => {
  const router = useRouter();

  return (
    <Styled.Button onClick={() => router.push('/user/pdf')}>
      <Image
        src="/images/salesclue_logo_white_alpha.png"
        width={98}
        height={23}
        priority
      />
    </Styled.Button>
  );
};

export default memo(Logo);
